export const tarifDocData = [
    {
        docName: "Распоряжение Комитета по ценам и тарифам МО от 18.12.2020г. №264-Р",
        url: "/tarifs/rasp_264-р_18.12.2020.pdf"
    },

    {
        docName: "Распоряжение Комитета по ценам и тарифам МО от 18.12.2020г. №306-Р",
        url: "/tarifs/rasp_306_Р_18.12.2020.pdf"
    },

    {
        docName: "Распоряжение Комитета по ценам и тарифам МО от 15.12.2020г. №246-Р",
        url: "/tarifs/rasp_246_Р_15.12.2020.pdf"
    },  

    {
        docName: "Распоряжение Комитета по ценам и тарифам МО от 20.12.2021г. №290-Р",
        url: "/tarifs/rasp_290-Р_20.12.2021.pdf"
    },

    {
        docName: "Распоряжение Комитета по ценам и тарифам МО от 16.12.2020г. №256-Р",
        url: "/tarifs/rasp_256_Р_16.12.2020.pdf"
    },

    {
        docName: "Распоряжение Комитета по ценам и тарифам МО от 17.09.2021г. №164-Р",
        url: "/tarifs/rasp_164_Р_17.09.2021.pdf"
    },

    {
        docName: "Распоряжение 169-Р от 19.07.2019 - Об установлении тарифов в сфере теплоснабжения",
        url: "/tarifs/rasporyazhenie_169-r_2019-07-19.pdf"
    },

    {
        docName: "Размер платы за содержание жилых помещений с 01.07.2019 - Постановление 610 от 29.05.2019",
        url: "/tarifs/postanovlenie_610_2019-05-29.pdf"
    },

    {
        docName: "Распоряжение 369-Р от 19.12.2018",
        url: "/tarifs/rasporyazhenie_369-r_2018-12-19.pdf"
    },

    {
        docName: "Распоряжение 374-Р от 19.12.2018",
        url: "/tarifs/rasporyazhenie_374-r_2018-12-19.pdf"
    },

    {
        docName: "Распоряжение 375-Р от 20.12.2018",
        url: "/tarifs/rasporyazhenie_375-r_2018-12-20.pdf"
    },

    {
        docName: "Распоряжение 392-Р от 20.12.2018",
        url: "/tarifs/rasporyazhenie_392-r_2018-12-20.pdf"
    },

    {
        docName: "Постановление 690/34 от 02.10.2018",
        url: "/tarifs/postanovlenie_690-34_2018-10-02.pdf"
    },

    {
        docName: "Постановление 2315 от 30.10.2018",
        url: "/tarifs/postanovlenie_2315_2018-10-30.pdf"
    }
]
