export const sanitarData = [
    { id: 1, address: "ул. Комсомольская д. 13", time: "с 9-00 до 12-00" },
    { id: 2, address: "ул. Комсомольская д. 15", time: "с 9-00 до 12-00" },
    { id: 3, address: "ул. Комсомольская д. 21", time: "с 9-00 до 12-00" },
    { id: 4, address: "ул. Комсомольская д. 23", time: "с 9-00 до 12-00" },
    { id: 5, address: "ул. Комсомольская д. 25", time: "с 9-00 до 12-00" },
    { id: 6, address: "ул. Комсомольская д. 17", time: "с 9-00 до 12-00" },
    { id: 7, address: "ул. Школьная д. 5", time: "с 9-00 до 12-00" },
    { id: 8, address: "ул. Сетевая д. 2", time: "с 15-00 до 17-00" },
    { id: 9, address: "ул. Сетевая д. 4", time: "с 15-00 до 17-00" },
    { id: 10, address: "ул. Сетевая д. 11", time: "с 15-00 до 17-00" },
    { id: 11, address: "ул. Сетевая д. 9", time: "с 15-00 до 17-00" },
    { id: 12, address: "ул. Сетевая д. 7", time: "с 15-00 до 17-00" },
    { id: 13, address: "ул. Клубная д. 1", time: "с 11-00 до 15-00" },
    { id: 14, address: "ул. Клубная д. 3", time: "с 11-00 до 15-00" },
    { id: 15, address: "ул. Клубная д. 6", time: "с 11-00 до 15-00" },
    { id: 16, address: "ул. Клубная д. 8", time: "с 9-00 до 10-00" },
    { id: 17, address: "ул. Клубная д. 9", time: "с 11-00 до 15-00" },
    { id: 18, address: "ул. Клубная д. 14", time: "с 11-00 до 15-00" },
    { id: 19, address: "ул. Клубная д. 5", time: "с 11-00 до 15-00" },
    { id: 20, address: "ул. Клубная д. 10", time: "с 11-00 до 15-00" },
    { id: 21, address: "ул. Клубная д. 12", time: "с 11-00 до 15-00" },
    { id: 22, address: "ул. Заводская д. 5", time: "с 11-00 до 15-00" },
    { id: 23, address: "ул. Заводская д. 2а", time: "с 11-00 до 15-00" },
    { id: 24, address: "ул. Средняя д. 22", time: "с 11-00 до 18-00" },
    { id: 25, address: "ул. Средняя д. 20", time: "с 11-00 до 18-00" },
    { id: 26, address: "ул. Ленина д. 11", time: "с 11-00 до 18-00" },
    { id: 27, address: "ул. Ленина д. 1", time: "с 11-00 до 18-00" },
    { id: 28, address: "ул. Ленина д. 3", time: "с 11-00 до 18-00" },
    { id: 29, address: "ул. Ленина д. 5", time: "с 11-00 до 18-00" },
    { id: 30, address: "ул. Ленина д. 7", time: "с 11-00 до 18-00" },
    { id: 31, address: "ул. Ленина д. 13", time: "с 11-00 до 18-00" },
    { id: 32, address: "ул. 1-ая Южная д. 18", time: "с 11-00 до 18-00" },
    { id: 33, address: "ул. 1-ая Южная д. 24", time: "с 11-00 до 18-00" },
    { id: 34, address: "ул. 1-ая Южная д.26", time: "с 11-00 до 18-00" },
    { id: 35, address: "ул. 1-ая Южная д. 28", time: "с 11-00 до 18-00" },
    { id: 36, address: "ул. 1-ая Южная д. 22", time: "с 11-00 до 18-00" },
    { id: 37, address: "ул. 1-ая Южная д. 20", time: "с 11-00 до 18-00" },
    { id: 38, address: "ул. Шоссейная д. 4", time: "с 8-30 до 9-00" },
    { id: 39, address: "ул. Менделеева д. 31", time: "с 8-00 до 9-00" },
    { id: 40, address: "ул. Менделеева д. 40", time: "с 8-00 до 9-00" },
    { id: 41, address: "ул. Орджоникидзе д. 41", time: "с 8-00 до 9-00" },
    { id: 42, address: "д. Могильцы д. 1", time: "с 9-00 до 11-00" },
    { id: 43, address: "ул. Дальняя д. 1", time: "с 17-00 до 19-00" },
    { id: 44, address: "ул. Дальняя д. 2", time: "с 17-00 до 19-00" },
    { id: 45, address: "ул. Дальняя д. 3", time: "с 17-00 до 19-00" },
    { id: 46, address: "ул. Дальняя д. 4", time: "с 17-00 до 19-00" },
    { id: 47, address: "ул. Дальняя д. 5", time: "с 17-00 до 19-00" },
    { id: 48, address: "ул. Дальняя д. 6", time: "с 17-00 до 19-00" },
    { id: 49, address: "ул. Крайняя д. 12", time: "с 8-30 до 10-30" },
    { id: 50, address: "ул. Крайняя д. 10", time: "с 8-30 до 10-30" },
    { id: 51, address: "ул. Экспериментальная д. 5", time: "с 8-30 до 10-30" },
    { id: 52, address: "ул. Экспериментальная д. 7", time: "с 8-30 до 10-30" },
    { id: 53, address: "ул. Дурова д.13", time: "с 15-30 до 17-00" },
    { id: 54, address: "ул. Дурова д. 17", time: "с 15-30 до 17-00" },
    { id: 55, address: "д. Талицы д. 1", time: "с 15-30 до 17-00" }
]