import './responsibility.css'

export const RespData = [
{
    id: 1,
    date: "03.08.2017",
    address: "п. Софрино, ул. Крайняя, д. 10",
    reason: [
        <ul>
            <li>Убрать электронные провода в защитные короба.</li>
            <li>Восстановить разрушенные участки цоколя.</li>
            <li>Произвести ремонт отмостки в местах разрушения.</li>
            <li>Восстановить окрасочный и штукатурный слой подъездов в местах разрушений.</li>
            <li>Обеспечить исправное остекленение в подъездах.</li>
            <li>Произвести ремонт козырьков по местам разрушений.</li>
            <li>Произвести ремонт крылец по местам разрушений.</li>
        </ul>
    ],
    measure: "предписание выполнено",
    amount: "125",
    payment: "125",
    document: "Постановление №5-443/2017 от 11.12.2017"
},

{
    id: 2,
    date: "24.01.2018",
    address: "д. Талицы, д. 1",
    reason: [
        <ul>
            <li>Провести ремонт поврежденных мест балконной плиты.</li>
            <li>Провести ремонт стены кв. 4. Устранить повреждение перегородок.</li>
            <li>Восстановить поврежденные участки цоколя дома.</li>
        </ul>
    ],
    measure: "предписание выполнено",
    amount: "125",
    payment: "125",
    document: "№ 5-99/2018 от 13.03.2018 г."
},

{
    id: 3,
    date: "13.08.2018",
    address: "ул. Экспериментальная, д. 13",
    reason: [
        <ul>
            <li>Обеспечить исправное состояние отмостки дома по всему периметру. </li>
            <li>Привести в надлежащее состояние инженерное оборудование, устранить уиечки. Провести осушение подвального помещения.</li>
            <li>Обеспечить уборку строительного и бытового мусора в подвальном помещении.</li>
        </ul>
    ],
    measure: "предписание выполнено",
    amount: "125",
    payment: "125",
    document: "№ 5-414/2018 от 28.08.2018"
},

{
    id: 4,
    date: "13.08.2018",
    address: "ул. Дальняя, д. 4",
    reason: [
        <ul>
            <li>Обеспечить исправное состояние цоколя дома с задней стороны по всей длине дома.</li>
            <li>Привести в надлежащее состояние инженерное оборудование, устранить утечки. Провести осушение подвального помещения.</li>
            <li>Обеспечить наружные входные двери в подъездах дома уплотнительными прокладками, предназначенными для снижения теплопотерь и шума.</li>
        </ul>
    ],
    measure: "предписание выполнено",
    amount: "125",
    payment: "125",
    document: "№ 5-418/2018 от 28.08.2018"
},

{
    id: 5,
    date: "13.08.2018",
    address: "ул. Дальняя, д. 2",
    reason: [
        <ul>
            <li>Обеспечить исправное состояние отмостки дома по всему периметру. </li>
            <li>Привести в надлежащее состояние инженерное оборудование, устранить утечки. Провести осушение подвального помещения.</li>
            <li>Обеспечить надлежащее состояние теплоизоляции трубопроводов ГВС и отопления в подвальном помещении.</li>
            <li>Обеспечить наружные входные двери в подъездах дома утеплительными прокладками и доводчиками, предназначенными для снижения теплопотерь и шума.</li>
        </ul>
    ],
    measure: "предписание выполнено",
    amount: "125",
    payment: "125",
    document: "№ 5-415/2018 от 28.08.2018"
},

{
    id: 6,
    date: "19.01.2021",
    address: "ул.Сетевая, д.2",
    reason: [
        <ul>
            <li>Восстановить окрасочно-штукатурный слой стен и потолков в подъезде №1 на 5 этаже.</li>
            <li>Выполнить ремонт кровли и устранить протечки кровли над подъездом № 1.</li>
        </ul>
    ],
    measure: "предписание выполнено",
    amount: "-",
    payment: "-",
    document: "№ 08ОГ/04-8-28-42021 от 22.01.2021"
},

{
    id: 7,
    date: "12.04.2021",
    address: "ул.Клубная, д.1",
    reason: [
        <ul>
            <li>Выполнить ремонт кровли в местах разрушения шиферного покрытия</li>
            <li>Выполнить ремонт фасада в местах разрушения штукатурного слоя по периметру здания отдельными местами.</li>
        </ul>
    ],
    measure: "предписание выполнено",
    amount: "-",
    payment: "-",
    document: "№ 08ОБ-20012-28-4-2021 от 13.04.2021"
},

{
    id: 8,
    date: "12.04.2021",
    address: "Клубная, д.5",
    reason: [
        <ul>
            <li>Очистить кровлю и козырьки от наледи и сосулек</li>
        </ul>
    ],
    measure: "предписание выполнено",
    amount: "-",
    payment: "-",
    document: "№08ВХ/03-46-28-4-2022/3/1 от 24.01.2022"
},

{
    id: 9,
    date: "12.04.2021",
    address: "Клубная, д.6",
    reason: [
        <ul>
            <li>Очистить кровлю и козырьки от наледи и сосулек</li>
        </ul>
    ],
    measure: "предписание выполнено",
    amount: "-",
    payment: "-",
    document: "№08ВХ/03-46-28-4-2022/3/2 от 24.01.2022"
},

{
    id: 10,
    date: "12.04.2021",
    address: "Клубная, д.12",
    reason: [
        <ul>
            <li>Восстановить окрасочно-штукатурный слой стен и потолков в подъезде №1 на 5 этаже.</li>
            <li>Выполнить ремонт кровли и устранить протечки кровли над подъездом № 1.</li>
        </ul>
    ],
    measure: "предписание выполнено",
    amount: "-",
    payment: "-",
    document: "№08ВХ/03-46-28-4-2022/3/2 от 24.01.2022"
},

{
    id: 11,
    date: "12.04.2021",
    address: "Клубная, д.12",
    reason: [
        <ul>
            <li>Очистить кровлю и козырьки от наледи и сосулек</li>
        </ul>
    ],
    measure: "предписание выполнено",
    amount: "-",
    payment: "-",
    document: "№08ВХ/03-46-28-4-2022/3/3 от 24.01.2022"
},

{
    id: 12,
    date: "12.04.2021",
    address: "Клубная, д.14",
    reason: [
        <ul>
            <li>Очистить кровлю и козырьки от наледи и сосулек</li>
        </ul>
    ],
    measure: "предписание выполнено",
    amount: "-",
    payment: "-",
    document: "№08ВХ/03-46-28-4-2022/3/4 от 24.01.2022"
},

{
    id: 13,
    date: "12.04.2021",
    address: "ул.Ленина, д. 1",
    reason: [
        <ul>
            <li>Очистить кровлю и козырьки от наледи и сосулек</li>
        </ul>
    ],
    measure: "предписание выполнено",
    amount: "-",
    payment: "-",
    document: "№08ВХ/03-46-28-4-2022/3/5 от 24.01.2022"
},

{
    id: 14,
    date: "12.04.2021",
    address: "ул.Ленина, д. 3",
    reason: [
        <ul>
            <li>Очистить кровлю и козырьки от наледи и сосулек</li>
        </ul>
    ],
    measure: "предписание выполнено",
    amount: "-",
    payment: "-",
    document: "№08ВХ/03-46-28-4-2022/3/6 от 24.01.2022"
},

{
    id: 15,
    date: "12.04.2021",
    address: "ул.Ленина, д.5",
    reason: [
        <ul>
            <li>Очистить кровлю и козырьки от наледи и сосулек</li>
        </ul>
    ],
    measure: "предписание выполнено",
    amount: "-",
    payment: "-",
    document: "№08ВХ/03-46-28-4-2022/3/7 от 24.01.2022"
}
]