export const YuzhnayaData = [
    {
        title: "дом №18",
        year: '1980',
        floor: "4",
        apartment: "16",
        quantityLC: "16",
        livingArea: "770,9",
        notLivinigArea: "нет",
        commonProperty: "83,8",
        type: "многоэтажный жилой дом",
        level: "ХВС, ГВС, отопление, канализация, газоснабжение, электроснабжение",
        technicalSupport: "общедомовой прибор учета ХВС",
        contractURL: 'yuzhnaya/sofrino_yuzhnaya_18_dogovor.pdf',
        protocolURL: 'protocol_4_2017-02-16.pdf',
    },

    {
        title: "дом №20",
        year: '1980',
        floor: "4",
        apartment: "16",
        quantityLC: "16",
        livingArea: "782",
        notLivinigArea: "нет",
        commonProperty: "83,8",
        type: "многоэтажный жилой дом",
        level: "ХВС, ГВС, отопление, канализация, газоснабжение, электроснабжение",
        technicalSupport: "общедомовой прибор учета ХВС",
        contractURL: 'yuzhnaya/sofrino_yuzhnaya_20_dogovor.pdf',
        protocolURL: 'protocol_1_2017-07-03.pdf',
    },

    {
        title: "дом №22",
        year: '1980',
        floor: "4",
        apartment: "16",
        quantityLC: "16",
        livingArea: "776,9",
        notLivinigArea: "нет",
        commonProperty: "68,5",
        type: "многоэтажный жилой дом",
        level: "ХВС, ГВС, отопление, канализация, газоснабжение, электроснабжение",
        technicalSupport: "общедомовой прибор учета ХВС",
        contractURL: 'yuzhnaya/sofrino_yuzhnaya_22_dogovor.pdf',
        protocolURL: 'protocol_1_2017-06-05.pdf',
    },

    {
        title: "дом №24",
        year: '1980',
        floor: "4",
        apartment: "16",
        quantityLC: "17",
        livingArea: "762,2",
        notLivinigArea: "нет",
        commonProperty: "66,8",
        type: "многоэтажный жилой дом",
        level: "ХВС, ГВС, отопление, канализация, газоснабжение, электроснабжение",
        technicalSupport: "общедомовой прибор учета ХВС",
        contractURL: 'yuzhnaya/sofrino_yuzhnaya_24_dogovor.pdf',
        protocolURL: 'protocol_4_2017-02-16.pdf',
    },

    {
        title: "дом №26",
        year: '1981',
        floor: "4",
        apartment: "16",
        quantityLC: "16",
        livingArea: "773,5",
        notLivinigArea: "нет",
        commonProperty: "70,3",
        type: "многоэтажный жилой дом",
        level: "ХВС, ГВС, отопление, канализация, газоснабжение, электроснабжение",
        technicalSupport: "общедомовой прибор учета ХВС",
        contractURL: 'yuzhnaya/sofrino_yuzhnaya_26_dogovor.pdf',
        protocolURL: 'protocol_4_2017-02-16.pdf',
    },

    {
        title: "дом №28",
        year: '1981',
        floor: "4",
        apartment: "16",
        quantityLC: "16",
        livingArea: "784,1",
        notLivinigArea: "нет",
        commonProperty: "71,2",
        type: "многоэтажный жилой дом",
        level: "ХВС, ГВС, отопление, канализация, газоснабжение, электроснабжение",
        technicalSupport: "общедомовой прибор учета ХВС",
        contractURL: 'yuzhnaya/sofrino_yuzhnaya_28_dogovor.pdf',
        protocolURL: 'protocol_4_2017-02-16.pdf',
    }

]