export const gazData = [
{
    id: 1,
    group: "Приготовление пищи и нагрев воды с использованием газовой плиты (в соответствие других направлений использования газа)",
    measure: "руб./куб.м.",
    price: "7,85"
},

{
    id: 2,
    group: "Приготовление пищи и нагрев воды с использованием газовой плиты и нагрев воды с использованием газового водонагревателя при отсутствии центрального горячего водоснабжения (в соответствие других направлений использования газа)",
    measure: "руб./куб.м.",
    price: "6,92"
},

{
    id: 3,
    group: "Отопление с одновременным использованием газа по направлениям, указанным в пунктах 1и 2",
    measure: "руб./1000 куб.м.",
    price: "6876,89"
},

{
    id: 4,
    group: "Индивидуальное (поквартирное) отопление жилых помещений сверх стандарта нормативной площади жилого помещения при отсутствии приборов учета газа",
    measure: "руб./1000 куб.м.",
    price: "8191,04"
}

]