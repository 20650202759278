export const meetingData = [
    { id: 1, 
    address: "ул. Дальняя, д. 3", 
    protocolNumber: 1, 
    protocolDate: "03.04.2017 г.", 
    decision: "Выбор управляющей компании ООО 'Веха'" },
    { id: 2, address: "ул. Дальняя д. 5", protocolNumber: 1, protocolDate: "12.04.2017 г.", decision: "Выбор управляющей компании ООО 'Веха'" },
    { id: 3, address: "ул. Дальняя д. 2", protocolNumber: 1, protocolDate: "14.04.2017 г.", decision: "Выбор управляющей компании ООО 'Веха'" },
    { id: 4, address: "д. Могильцы д. 1", protocolNumber: "б/н", protocolDate: "26.04.2017 г.", decision: "Включить в план текущего ремонта на 2017 г. - ремонт подъездов" },
    { id: 5, address: "ул. Дальняя д. 3", protocolNumber: "б/н", protocolDate: "20.05.2017 г.", decision: "Включить в план текущего ремонта на 2017 г. - ремонт подъездов" },
    { id: 6, address: "ул. Клубная д. 10", protocolNumber: "б/н", protocolDate: "22.05.2017 г.", decision: "Включить в план текущего ремонта на 2017 г. - ремонт подъездов" },
    { id: 7, address: "ул. Дальняя д. 5", protocolNumber: "б/н", protocolDate: "23.05.2017 г.", decision: "Включить в план текущего ремонта на 2017 г. - ремонт подъездов" },
    { id: 8, address: "ул. Дальняя д. 6", protocolNumber: "б/н", protocolDate: "23.05.2017 г.", decision: "Включить в план текущего ремонта на 2017 г. - ремонт подъездов" },
    { id: 9, address: "ул. Средняя д. 20", protocolNumber: "б/н", protocolDate: "24.05.2017 г.", decision: "Включить в план текущего ремонта на 2017 г. - ремонт подъездов" },
    { id: 10, address: "ул. 1-ая Южная д. 28", protocolNumber: "б/н", protocolDate: "24.05.2017 г.", decision: "Включить в план текущего ремонта на 2017 г. - ремонт подъезда" },
    { id: 11, address: "ул. 1-ая Южная д. 24", protocolNumber: "б/н", protocolDate: "25.05.2017 г.", decision: "Включить в план текущего ремонта на 2017 г. - ремонт подъезда" },
    { id: 12, address: "ул. Крайняя д. 10", protocolNumber: "б/н", protocolDate: "25.05.2017 г.", decision: "В связи с отсутствием кворума, общее собрание собственников жилых помещений является не состоявшимся." },
    { id: 13, address: "ул. 1-я Южная д. 22", protocolNumber: 1, protocolDate: "05.06.2017 г.", decision: "Выбор управляющей компании ООО 'Веха'" },
    { id: 14, address: "ул. Клубная д. 9", protocolNumber: "б/н", protocolDate: "06.06.2017 г.", decision: "Включить в план капитального ремонта на 2018 г. - капитальный ремонт кровли, утепление фасада" },
    { id: 15, address: "ул. Менделеева д. 31", protocolNumber: "б/н", protocolDate: "06.06.2017 г.", decision: "В связи с отсутствием кворума, общее собрание собственников жилых помещений является не состоявшимся." },
    { id: 16, address: "ул. Менделеева д. 40", protocolNumber: "б/н", protocolDate: "06.06.2017 г.", decision: "Провести техническое обследование конструктивных элементов дома в 2017 г. за счет средств Фонда капитального ремонта" },
    { id: 17, address: "ул. Орджоникидзе д. 41", protocolNumber: "б/н", protocolDate: "06.06.2017 г.", decision: "Провести техническое обследование конструктивных элементов дома в 2017 г. за счет средств Фонда капитального ремонта" },
    { id: 18, address: "ул. Клубная д. 14", protocolNumber: "б/н", protocolDate: "10.06.2017 г.", decision: "В связи с отсутствием кворума, общее собрание собственников жилых помещений является не состоявшимся." },
    { id: 19, address: "ул. Клубная д. 6", protocolNumber: "б/н", protocolDate: "10.06.2017 г.", decision: "В связи с отсутствием кворума, общее собрание собственников жилых помещений является не состоявшимся." },
    { id: 20, address: "ул. Клубная д. 12", protocolNumber: "б/н", protocolDate: "17.06.2017 г.", decision: "Провести техническое обследование конструктивных элементов дома в 2017 г. за счет средств Фонда капитального ремонта" },
    { id: 21, address: "ул. Школьная д. 5", protocolNumber: "б/н", protocolDate: "21.06.2017 г.", decision: "В связи с отсутствием кворума, общее собрание собственников жилых помещений является не состоявшимся." },
    { id: 22, address: "ул. Ленина д. 13", protocolNumber: "б/н", protocolDate: "21.06.2017 г.", decision: "Провести техническое обследование конструктивных элементов дома в 2017 г. за счет средств Фонда капитального ремонта" },
    { id: 23, address: "ул. 1-я Южная д. 20", protocolNumber: 1, protocolDate: "03.07.2017 г.", decision: "Выбор управляющей компании ООО 'Веха'" },
    { id: 24, address: "ул. Клубная д. 1", protocolNumber: "б/н", protocolDate: "07.07.2017 г.", decision: "Провести техническое обследование конструктивных элементов дома в 2017 г. за счет средств Фонда капитального ремонта" },
    { id: 25, address: "ул. Клубная д. 3", protocolNumber: "б/н", protocolDate: "07.07.2017 г.", decision: "Провести техническое обследование конструктивных элементов дома в 2017 г. за счет средств Фонда капитального ремонта" },
    { id: 26, address: "ул. Дурова д. 17", protocolNumber: "б/н", protocolDate: "14.07.2017 г.", decision: "Провести техническое обследование конструктивных элементов дома в 2017 г. за счет средств Фонда капитального ремонта" },
    { id: 27, address: "ул. Ленина д. 11", protocolNumber: "б/н", protocolDate: "27.07.2017 г.", decision: "Включить в план капитального ремонта на 2018 г. - капитальный ремонт отмостки" },
    { id: 28, address: "ул. Комсомольская д. 15", protocolNumber: "б/н", protocolDate: "28.07.2017 г.", decision: "В связи с отсутствием кворума, общее собрание собственников жилых помещений является не состоявшимся." },
    { id: 29, address: "ул. Комсомольская д. 21", protocolNumber: "б/н", protocolDate: "28.07.2017 г.", decision: "В связи с отсутствием кворума, общее собрание собственников жилых помещений является не состоявшимся." },
    { id: 30, address: "ул. Комсомольская д. 23", protocolNumber: "б/н", protocolDate: "28.07.2017 г.", decision: "В связи с отсутствием кворума, общее собрание собственников жилых помещений является не состоявшимся." },
    { id: 31, address: "ул. Комсомольская д. 25", protocolNumber: "б/н", protocolDate: "28.07.2017 г.", decision: "В связи с отсутствием кворума, общее собрание собственников жилых помещений является не состоявшимся." },
    { id: 32, address: "ул. Комсомольская д. 17", protocolNumber: "б/н", protocolDate: "28.07.2017 г.", decision: "В связи с отсутствием кворума, общее собрание собственников жилых помещений является не состоявшимся." },
    { id: 33, address: "ул. Сетевая д. 11", protocolNumber: "б/н", protocolDate: "28.07.2017 г.", decision: "Включить в план капитального ремонта на 2018 г. - капитальный ремонт кровли" },
    { id: 34, address: "ул. Сетевая д. 9", protocolNumber: "б/н", protocolDate: "28.07.2017 г.", decision: "Включить в план капитального ремонта на 2018 г. - капитальный ремонт кровли" },
    { id: 35, address: "ул. Сетевая д. 7", protocolNumber: "б/н", protocolDate: "28.07.2017 г.", decision: "Включить в план капитального ремонта на 2018 г. - капитальный ремонт кровли" },
    { id: 36, address: "ул. Ленина д. 5", protocolNumber: "б/н", protocolDate: "31.07.2017 г.", decision: "В связи с отсутствием кворума, общее собрание собственников жилых помещений является не состоявшимся." },
    { id: 37, address: "ул. Ленина д. 7", protocolNumber: "б/н", protocolDate: "31.07.2017 г.", decision: "В связи с отсутствием кворума, общее собрание собственников жилых помещений является не состоявшимся." },
    { id: 38, address: "ул. Экспериментальная д. 13", protocolNumber: "б/н", protocolDate: "31.07.2017 г.", decision: "В связи с отсутствием кворума, общее собрание собственников жилых помещений является не состоявшимся." },
    { id: 39, address: "ул. Дурова д. 13", protocolNumber: "б/н", protocolDate: "31.07.2017 г.", decision: "В связи с отсутствием кворума, общее собрание собственников жилых помещений является не состоявшимся." },
    { id: 40, address: "ул. Средняя д. 22", protocolNumber: "б/н", protocolDate: "31.07.2017 г.", decision: "В связи с отсутствием кворума, общее собрание собственников жилых помещений является не состоявшимся." },
    { id: 41, address: "ул. Дальняя д. 4", protocolNumber: 1, protocolDate: "07.11.2017 г.", decision: "Выбор управляющей компании ООО 'Веха'" },
    { id: 42, address: "д. Талицы д. 1", protocolNumber: "б/н", protocolDate: "12.12.2017 г.", decision: "В связи с отсутствием кворума, общее собрание жилых помещений является не состоявшимся." },
    { id: 43, address: "ул. Заводская д. 5", protocolNumber: "б/н", protocolDate: "17.12.2017 г.", decision: "Включить в план капитального ремонта на 2018 г. - капитальный ремонт фасада, отмостки, фундамента" },
    { id: 44, address: "ул. 1-ая Южная д. 18", protocolNumber: "б/н", protocolDate: "25.12.2017 г.", decision: "Включить в план капитального ремонта на 2018 г. - капитальный ремонт кровли, цоколя, отмостки, утепление фасада." },
    { id: 45, address: "ул. Комсомольская д. 13", protocolNumber: 1, protocolDate: "23.01.2018 г.", decision: "Включить в план текущего ремонта на 2018 г. - ремонт подъездов" },
    { id: 46, address: "ул. Клубная д. 5", protocolNumber: 1, protocolDate: "06.02.2018 г.", decision: "Включить в план капитального ремонта на 2018 г. - капитальный ремонт ВИС" },
    { id: 47, address: "ул. Ленина д. 1", protocolNumber: 1, protocolDate: "19.02.2018 г.", decision: "Включить в план текущего ремонта на 2018 г. - ремонт подъездов" },
    { id: 48, address: "ул. Ленина д. 3", protocolNumber: 1, protocolDate: "12.03.2018 г.", decision: "Включить в план текущего ремонта на 2018 г. - ремонт подъездов" },
    { id: 49, address: "ул. 1-ая Южная д.26", protocolNumber: 1, protocolDate: "12.03.2018 г.", decision: "Включить в план текущего ремонта на 2018 г. - ремонт подъезда" },
    { id: 50, address: "ул. 1-ая Южная д.28", protocolNumber: 1, protocolDate: "19.03.2018 г.", decision: "Включить в план текущего ремонта на 2018 г. - ремонт подъездов" },
    {
        id: 51,
        address: "ул. Дальняя д. 2",
        category: 1,
        protocolNumber: "12.03.2018 г.",
        protocolDate: "12.03.2018 г.",
        decision: "Включить в план текущего ремонта на 2018 г. - ремонт подъездов"
      },
      {
        id: 52,
        address: "ул. 1-я Южная д. 22",
        category: 1,
        protocolNumber: "12.03.2018 г.",
        protocolDate: "12.03.2018 г.",
        decision: "Включить в план текущего ремонта на 2018 г. - ремонт подъезда"
      },
      {
        id: 53,
        address: "ул. 1-я Южная д. 20",
        category: 1,
        protocolNumber: "12.03.2018 г.",
        protocolDate: "12.03.2018 г.",
        decision: "Включить в план текущего ремонта на 2018 г. - ремонт подъезда"
      },
      {
        id: 54,
        address: "ул. Сетевая д. 2",
        category: 1,
        protocolNumber: "19.03.2018",
        protocolDate: "19.03.2018",
        decision: "Включить в план текущего ремонта на 2018 г. - ремонт подъездов; включить в план капитального ремонта на 2019 г. - капитальный ремонт кровли"
      },
      {
        id: 55,
        address: "ул. Шоссейная д. 4",
        category: 1,
        protocolNumber: "22.03.2018 г.",
        protocolDate: "22.03.2018 г.",
        decision: "Включить в план текущего ремонта на 2018 г. - ремонт подъезда"
      },
      {
        id: 56,
        address: "ул. Заводская д. 2а",
        category: 1,
        protocolNumber: "26.03.2018 г.",
        protocolDate: "26.03.2018 г.",
        decision: "Включить в план текущего ремонта на 2018 г. - ремонт подъездов"
      },
      {
        id: 57,
        address: "ул. Крайняя д. 12",
        category: 1,
        protocolNumber: "26.03.2018 г.",
        protocolDate: "26.03.2018 г.",
        decision: "Включить в план текущего ремонта на 2018 г. - ремонт подъездов; включить в план капитального ремонта на 2019 г. - капитальный ремонт подвальных помещений, ВИС, электро-, газо-, тепло-, водоснабжения, кнализации"
      },
      {
        id: 58,
        address: "ул. Экспериментальная д. 7",
        category: 1,
        protocolNumber: "23.04.2018",
        protocolDate: "23.04.2018",
        decision: "Включить в план текущего ремонта на 2018 г. - ремонт подъездов"
      },
      {
        id: 59,
        address: "ул. Экспериментальная д. 5",
        category: 1,
        protocolNumber: "31.07.2018",
        protocolDate: "31.07.2018",
        decision: "Выбор управляющей компании ООО 'Веха'"
      },
      {
        id: 60,
        address: "ул. Экспериментальная д. 1",
        category: 1,
        protocolNumber: "12.08.2018",
        protocolDate: "12.08.2018",
        decision: "Выбор управляющей компании ООО 'Веха'"
      },
      {
        id: 61,
        address: "д. Талицы д. 1",
        category: "б/н",
        protocolNumber: "20.08.2018",
        protocolDate: "20.08.2018",
        decision: "провести техническое обследование конструктивных элементов дома в 2019 г. за счет средств Фонда капитального ремонта; выбор совета дома"
      },
      {
        id: 62,
        address: "ул. Сетевая д. 4",
        category: 1,
        protocolNumber: "22.08.2018",
        protocolDate: "22.08.2018",
        decision: "Выбор управляющей компании ООО 'Веха'"
      },
      {
        id: 63,
        address: "ул. Экспериментальная д. 5",
        category: 2,
        protocolNumber: "24.09.2018",
        protocolDate: "24.09.2018",
        decision: "Включить в план текущего ремонта на 2019 г. - ремонт подъездов"
      },
      {
        id: 64,
        address: "ул. Экспериментальная д. 13",
        category: 1,
        protocolNumber: "26.09.2018",
        protocolDate: "26.09.2018",
        decision: "Включить в план текущего ремонта на 2019 г. - ремонт подъездов; включить в план капитального ремонта на 2019 г. - капитальный ремонт кровли, фасада, ВИС"
      },
      {
        id: 65,
        address: "ул. Сетевая д. 4",
        category: 2,
        protocolNumber: "10.10.2018",
        protocolDate: "10.10.2018",
        decision: "О переносе проведения капитального ремонта, установленного Региональной программой капитального ремонта на более поздний срок."
      },
      {
        id: 66,
        address: "ул. Эксперименатльная д. 1",
        category: 2,
        protocolNumber: "23.10.2018",
        protocolDate: "23.10.2018",
        decision: "Включить в план текущего ремонта на 2019 г. - ремонта подъездов"
      },
      {
        id: 67,
        address: "ул. Орджоникидзе д. 41",
        category: 1,
        protocolNumber: "26.10.2018",
        protocolDate: "26.10.2018",
        decision: "Включить в план текущего ремонта на 2019 г. - ремонта подъездов"
      },
      {
        id: 68,
        address: "ул. Менделеева д. 31",
        category: "б/н",
        protocolNumber: "08.12.2018",
        protocolDate: "08.12.2018",
        decision: "Провести техническое обследование конструктивных элементов дома в 2019 г. за счет средств Фонда капитального ремонта; выбор совета дома"
      },
      {
        id: 69,
        address: "ул. Сетевая д. 7",
        category: "б/н",
        protocolNumber: "28.01.2019",
        protocolDate: "28.01.2019",
        decision: "Включить в план текущего ремонта на 2020 г. - ремонта подъездов"
      },
      {
        id: 70,
        address: "ул. Клубная д. 12",
        category: "б/н",
        protocolNumber: "20.02.2019",
        protocolDate: "20.02.2019",
        decision: "Включить в план капитального ремонта на 2019 г. - проведение внутридомовой инженерной системы горячего водоснабжения"
      },
      {
        id: 71,
        address: "ул. Клубная д. 10",
        category: "б/н",
        protocolNumber: "20.02.2019",
        protocolDate: "20.02.2019",
        decision: "Включить в план капитального ремонта на 2019 г. - проведение внутридомовой инженерной системы горячего водоснабжения"
      },
      {
        id: 72,
        address: "ул. Клубная д. 8",
        category: "б/н",
        protocolNumber: "20.02.2019",
        protocolDate: "20.02.2019",
        decision: "Включить в план капитального ремонта на 2019 г. - проведение внутридомовой инженерной системы горячего водоснабжения"
      },
      {
        id: 73,
        address: "ул. Клубная д. 1",
        category: "б/н",
        protocolNumber: "23.02.2019",
        protocolDate: "23.02.2019",
        decision: "Включить в план капитального ремонта на 2019 г. - проведение внутридомовой инженерной системы горячего водоснабжения"
      },
      {
        id: 74,
        address: "ул. Дальняя, д. 4",
        category: 1,
        protocolNumber: "24.02.2019",
        protocolDate: "24.02.2019",
        decision: "Включить в план текущего ремонта на 2019 г. - ремонта подъездов"
      },
      {
        id: 75,
        address: "ул. Ленина, д. 7",
        category: 1,
        protocolNumber: "27.02.2019",
        protocolDate: "27.02.2019",
        decision: "Включить в план текущего ремонта на 2019 г. - ремонта подъездов"
      },
      {
        id: 76,
        address: "ул. Ленина, д. 5",
        category: 1,
        protocolNumber: "07.03.2019",
        protocolDate: "07.03.2019",
        decision: "Включить в план текущего ремонта на 2019 г. - ремонта подъездов"
      },
      {
        id: 77,
        address: "ул. Заводская, д. 5",
        category: 1,
        protocolNumber: "19.03.2019",
        protocolDate: "19.03.2019",
        decision: "Включить в план капитального ремонта на 2019 г. - ремонт фасада, отмостки, фундамента."
      },
      {
        id: 78,
        address: "ул. Комсомольская, д. 13",
        category: 1,
        protocolNumber: "20.03.2019",
        protocolDate: "20.03.2019",
        decision: "Включить в план капитального ремонта на 2019 г. - ремонт кровли."
      },
      {
        id: 79,
        address: "ул. Комсомольская, д. 13",
        category: 1,
        protocolNumber: "06.03.2020",
        protocolDate: "06.03.2020",
        decision: "Заключение собственниками помещений в МКД, действующими от своего имени, договора, содержащего положения о предоставлении коммунальных услуг, договора на оказание услуг по обращению с ТКО; Выбор управляющей компании ООО 'Веха'"
      },
      {
        id: 80,
        address: "ул. Комсомольская, д. 15",
        category: "1Э/2022",
        protocolNumber: "20.03.2019",
        protocolDate: "20.03.2019",
        decision: "Включить в план капитального ремонта на 2023-2025 г. - капитальный ремонт кровли"
      },
      {
        id: 81,
        address: "ул. Комсомольская, д. 13",
        category: 1,
        protocolNumber: "06.03.2020",
        protocolDate: "06.03.2020",
        decision: "Заключение собственниками помещений в МКД, действующими от своего имени, договора, содержащего положения о предоставлении коммунальных услуг, договора на оказание услуг по обращению с ТКО; Выбор управляющей компании ООО 'Веха'"
      },
      {
        id: 82,
        address: "ул. Комсомольская, д. 17",
        category: "4Э/2021",
        protocolNumber: "16.06.2021",
        protocolDate: "16.06.2021",
        decision: "Принятие решения о наделении управляющей организации ООО 'Веха' правом подписания договора по техническому обслуживанию внутриквартирного газового оборудования (ВКГО) от имени собственников многоквартирного дома; Заключение собственниками помещений в МКД, действующими от своего имени, договора Холодного водоснабжения, содержащего положения о предоставлении коммунальных услуг с ресурсоснабжающей организацией"
      },
      {
        id: 83,
        address: "ул. Комсомольская, д. 17",
        category: "2Э/2022",
        protocolNumber: "23.11.2020",
        protocolDate: "23.11.2020",
        decision: "В связи с отсутствием кворума, общее собрание собственников жилых помещений является не состоявшимся."
      },
      {
        id: 84,
        address: "ул. Комсомольская, д. 17",
        category: "5Э/2022",
        protocolNumber: "28.12.2020",
        protocolDate: "28.12.2020",
        decision: "В связи с отсутствием кворума, общее собрание собственников жилых помещений является не состоявшимся."
      },
      {
        id: 85,
        address: "ул. Комсомольская, д. 21",
        category: "2Э/2022",
        protocolNumber: "11.07.2022",
        protocolDate: "11.07.2022",
        decision: "В связи с отсутствием кворума, общее собрание собственников жилых помещений является не состоявшимся."
      },
      {
        id: 86,
        address: "ул. Комсомольская, д. 21",
        category: "1Э/2021",
        protocolNumber: "08.10.2021",
        protocolDate: "08.10.2021",
        decision: "В связи с отсутствием кворума, общее собрание собственников жилых помещений является не состоявшимся."
      },
      {
        id: 87,
        address: "ул. Комсомольская, д. 23",
        category: "1Э/2022",
        protocolNumber: "05.07.2022",
        protocolDate: "05.07.2022",
        decision: "Включить в план капитального ремонта на 2023-2025 г. - капитальный ремонт кровли,ВИС."
      },
      {
        id: 88,
        address: "ул. Комсомольская, д. 23",
        category: 1,
        protocolNumber: "20.03.2020",
        protocolDate: "20.03.2020",
        decision: "Заключение собственниками помещений в МКД, действующими от своего имени, договора, содержащего положения о предоставлении коммунальных услуг, договора на оказание услуг по обращению с ТКО; Выбор управляющей компании ООО 'Веха'"
      },
      {
        id: 89,
        address: "ул. Комсомольская, д. 25",
        category: 1,
        protocolNumber: "20.03.2020",
        protocolDate: "20.03.2020",
        decision: "Заключение собственниками помещений в МКД, действующими от своего имени, договора, содержащего положения о предоставлении коммунальных услуг, договора на оказание услуг по обращению с ТКО; Выбор управляющей компании ООО 'Веха'"
      },
      {
        id: 90,
        address: "ул. Школьная, д. 5",
        category: "1Э/2021",
        protocolNumber: "21.04.2021",
        protocolDate: "21.04.2021",
        decision: "Заключение собственниками помещений в МКД, действующими от своего имени, договора, содержащего положения о предоставлении коммунальных услуг, договора на оказание услуг по обращению с ТКО"
      },
      {
        id: 91,
        address: "ул. Сетевая, д. 2",
        category: "1Э/2022",
        protocolNumber: "29.04.2022",
        protocolDate: "29.04.2022",
        decision: "Включить в план капитального ремонта на 2023-2025 г. - капитальный ремонт кровли,ВИС"
      },
      {
        id: 92,
        address: "ул. Сетевая, д. 2",
        category: 1,
        protocolNumber: "09.12.2020",
        protocolDate: "09.12.2020",
        decision: "Заключение собственниками помещений в МКД, действующими от своего имени, договора, содержащего положения о предоставлении коммунальных услуг, договора на оказание услуг по обращению с ТКО; Выбор управляющей компании ООО 'Веха'"
      },
      {
        id: 93,
        address: "ул. Сетевая, д. 7",
        category: "1Э/2021",
        protocolNumber: "09.07.2021",
        protocolDate: "09.07.2021",
        decision: "В связи с отсутствием кворума, общее собрание собственников жилых помещений является не состоявшимся."
      },
      {
        id: 94,
        address: "ул. Сетевая, д. 7",
        category: "2Э/2021",
        protocolNumber: "18.11.2021",
        protocolDate: "18.11.2021",
        decision: "Заключение собственниками помещений в МКД, действующими от своего имени, договора, содержащего положения о предоставлении коммунальных услуг, договора на оказание услуг по обращению с ТКО; Выбор управляющей компании ООО 'Веха'"
      },
      {
        id: 95,
        address: "ул. Сетевая, д. 7",
        category: "3Э/2022",
        protocolNumber: "03.08.2022",
        protocolDate: "03.08.2022",
        decision: "В связи с отсутствием кворума, общее собрание собственников жилых помещений является не состоявшимся."
      },
      {
        id: 96,
        address: "ул. Сетевая, д. 9",
        category: "1Э/2021",
        protocolNumber: "30.09.2021",
        protocolDate: "30.09.2021",
        decision: "В связи с отсутствием кворума, общее собрание собственников жилых помещений является не состоявшимся."
      },
      {
        id: 97,
        address: "ул. Сетевая, д. 9",
        category: "2Э/2021",
        protocolNumber: "18.11.2021",
        protocolDate: "18.11.2021",
        decision: "Заключение собственниками помещений в МКД, действующими от своего имени, договора, содержащего положения о предоставлении коммунальных услуг, договора на оказание услуг по обращению с ТКО; Выбор управляющей компании ООО 'Веха'"
      },
      {
        id: 98,
        address: "ул. Сетевая, д. 9",
        category: "3Э/2022",
        protocolNumber: "03.08.2022",
        protocolDate: "03.08.2022",
        decision: "Включить в план капитального ремонта на 2023-2025 г. - капитальный ремонт кровли"
      },
      {
        id: 99,
        address: "ул. Сетевая, д. 11",
        category: "1Э/2021",
        protocolNumber: "30.09.2021",
        protocolDate: "30.09.2021",
        decision: "В связи с отсутствием кворума, общее собрание собственников жилых помещений является не состоявшимся."
      },
      {
        id: 100,
        address: "ул. Сетевая, д. 11",
        category: "2Э/2021",
        protocolNumber: "15.11.2021",
        protocolDate: "15.11.2021",
        decision: "Заключение собственниками помещений в МКД, действующими от своего имени, договора, содержащего положения о предоставлении коммунальных услуг, договора на оказание услуг по обращению с ТКО; Выбор управляющей компании ООО 'Веха'"
      },
      {
        id: 101,
        address: "ул. Сетевая, д. 11",
        category: "3Э/2022",
        protocolNumber: "03.08.2022",
        protocolDate: "03.08.2022",
        decision: "Включить в план капитального ремонта на 2023-2025 г. - капитальный ремонт кровли."
      },
      {
        id: 102,
        address: "ул. Клубная, д. 1",
        category: "1Э/2022",
        protocolNumber: "09.02.2022",
        protocolDate: "09.02.2022",
        decision: "Принятие решения о наделении управляющей организации ООО 'Веха' правом подписания договора по техническому обслуживанию внутриквартирного газового оборудования (ВКГО) от имени собственников многоквартирного дома"
      },
      {
        id: 103,
        address: "ул. Клубная, д. 1",
        category: "1Э/2022",
        protocolNumber: "06.09.2020",
        protocolDate: "06.09.2020",
        decision: "Выбор управляющей компании ООО «Веха». Утверждение проекта доп.соглашения к договору управления."
      },
      {
        id: 104,
        address: "ул. Клубная, д. 3",
        category: "1Э/2022",
        protocolNumber: "12.12.2022",
        protocolDate: "12.12.2022",
        decision: "Принятие решения о заключении собственниками жилых помещений МКД договора холодного водоснабжения, водоотведения, услуги по отоплению, оказание услуг по обращению с твердыми коммунальными отходами (ТКО) с ресурсоснабжающими организациями с «01» января 2023 года. Инициатор: ООО 'ВЕХА'."
      },
      {
        id: 105,
        address: "ул. Клубная, д. 6",
        category: "1Э/2022",
        protocolNumber: "09.02.2022",
        protocolDate: "09.02.2022",
        decision: "Принятие решения о наделении управляющей организации ООО 'Веха' правом подписания договора по техническому обслуживанию внутриквартирного газового оборудования (ВКГО) от имени собственников многоквартирного дома."
      },
      {
        id: 106,
        address: "ул. Клубная, д. 9",
        category: "1Э/2022",
        protocolNumber: "12.04.2022",
        protocolDate: "12.04.2022",
        decision: "Включить в план капитального ремонта на 2023-2025 г. - капитальный ремонт кровли."
      },
      {
        id: 107,
        address: "ул. Клубная, д. 9",
        category: 1,
        protocolNumber: "11.01.2022",
        protocolDate: "11.01.2022",
        decision: "Принятие решения о наделении управляющей организации ООО 'Веха' правом подписания договора по техническому обслуживанию внутриквартирного газового оборудования (ВКГО) от имени собственников многоквартирного дома. Утверждения плана текущего ремонта на 2022 год. Утверждение порядка пользования общим имуществом в подвальном помещении."
      },
      {
        id: 108,
        address: "ул. Клубная, д. 9",
        category: 1,
        protocolNumber: "13.05.2021",
        protocolDate: "13.05.2021",
        decision: "Заключение собственниками помещений в МКД, действующими от своего имени, договора горячего водоснабжения, содержащего положения о предоставлении коммунальных услуг с ресурсоснабжающей организацией."
      },
      {
        id: 109,
        address: "ул. Клубная, д. 10",
        category: "1Э/2022",
        protocolNumber: "30.05.2022",
        protocolDate: "30.05.2022",
        decision: "Включить в план капитального ремонта на 2023-2025 г. - капитальный ремонт фасада."
      },
      {
        id: 110,
        address: "ул. Клубная, д. 10",
        category: 1,
        protocolNumber: "14.10.2020",
        protocolDate: "14.10.2020",
        decision: "Выбор управляющей компании ООО 'Веха' Заключение собственниками помещений в МКД, действующими от своего имени, договора, содержащего положения о предоставлении коммунальных услуг, договора на оказание услуг по обращению с ТКО"
      },
      {
        id: 111,
        address: "ул. Клубная, д. 10",
        category: "1Э/2022",
        protocolNumber: "20.04.2021",
        protocolDate: "20.04.2021",
        decision: "Заключение собственниками помещений в МКД, действующими от своего имени, договора горячего водоснабжения, содержащего положения о предоставлении коммунальных услуг с ресурсоснабжающей организацией; Утверждение проекта доп.соглашения к договору управления; Включение в состав общего имущества МКД общедомовой системы горячего водоснабжения."
      },
      {
        id: 112,
        address: "ул. Клубная, д. 12",
        category: "1Э/2021",
        protocolNumber: "12.08.2021",
        protocolDate: "12.08.2021",
        decision: "Принятие решения о наделении управляющей организации ООО 'Веха' правом подписания договора по техническому обслуживанию внутриквартирного газового оборудования (ВКГО) от имени собственников многоквартирного дома."
      },
      {
        id: 113,
        address: "ул. Клубная, д. 12",
        category: 1,
        protocolNumber: "23.04.2021",
        protocolDate: "23.04.2021",
        decision: "Заключение собственниками помещений в МКД, действующими от своего имени, договора горячего водоснабжения, содержащего положения о предоставлении коммунальных услуг с ресурсоснабжающей организацией; Утверждение проекта доп.соглашения к договору управления; Включение в состав общего имущества МКД общедомовой системы горячего водоснабжения."
      },
      {
        id: 114,
        address: "ул. Клубная, д. 12",
        category: 1,
        protocolNumber: "23.03.2020",
        protocolDate: "23.03.2020",
        decision: "Выбор управляющей компании ООО 'Веха'."
      },
      {
        id: 115,
        address: "ул. Клубная, д. 14",
        category: 1,
        protocolNumber: "25.03.2020",
        protocolDate: "25.03.2020",
        decision: "Выбор управляющей компании ООО 'Веха'; Заключение собственниками помещений в МКД, действующими от своего имени, договора, содержащего положения о предоставлении коммунальных услуг, договора на оказание услуг по обращению с ТКО."
      },
      {
        id: 116,
        address: "ул. Клубная, д. 14",
        category: "1Э/2022",
        protocolNumber: "12.12.2022",
        protocolDate: "12.12.2022",
        decision: "Принятие решения о заключении собственниками жилых помещений МКД договора холодного водоснабжения, водоотведения, услуги по отоплению, оказание услуг по обращению с твердыми коммунальными отходами (ТКО) с ресурсоснабжающими организациями с '01' января 2023 года. Инициатор: ООО 'ВЕХА'."
      },
  {
    id: 117,
    address: "ул. Заводская, д. 2а",
    category: "3Э/2020",
    protocolNumber: "28.12.2020",
    protocolDate: "28.12.2020",
    decision: "В связи с отсутствием кворума, общее собрание собственников жилых помещений является не состоявшимся."
  },
  {
    id: 118,
    address: "ул. Заводская, д. 2а",
    category: "4Э/2021",
    protocolNumber: "16.06.2021",
    protocolDate: "16.06.2021",
    decision: "В связи с отсутствием кворума, общее собрание собственников жилых помещений является не состоявшимся."
  },
  {
    id: 119,
    address: "ул. Заводская, д. 5",
    category: "1Э/2021",
    protocolNumber: "22.06.2021",
    protocolDate: "22.06.2021",
    decision: "В связи с отсутствием кворума, общее собрание собственников жилых помещений является не состоявшимся."
  },
  {
    id: 120,
    address: "ул. Средняя, д. 20",
    category: "1Э/2021",
    protocolNumber: "22.06.2021",
    protocolDate: "22.06.2021",
    decision: "В связи с отсутствием кворума, общее собрание собственников жилых помещений является не состоявшимся."
  },
  {
    id: 121,
    address: "ул. Средняя, д. 22",
    category: "1Э/2022",
    protocolNumber: "23.08.2022",
    protocolDate: "23.08.2022",
    decision: "Выбор управляющей компании ООО 'Веха'; Заключение собственниками помещений в МКД, действующими от своего имени, договора, содержащего положения о предоставлении коммунальных услуг, договора на оказание услуг по обращению с ТКО."
  },
  {
    id: 122,
    address: "ул. Ленина, д. 1",
    category: "1Э/2021",
    protocolNumber: "14.12.2021",
    protocolDate: "14.12.2021",
    decision: "В связи с отсутствием кворума, общее собрание собственников жилых помещений является не состоявшимся."
  },
  {
    id: 123,
    address: "ул. Ленина, д. 3",
    category: "1Э/2021",
    protocolNumber: "14.12.2021",
    protocolDate: "14.12.2021",
    decision: "В связи с отсутствием кворума, общее собрание собственников жилых помещений является не состоявшимся."
  },
  {
    id: 124,
    address: "ул. Ленина, д. 5",
    category: "1Э/2021",
    protocolNumber: "21.12.2021",
    protocolDate: "21.12.2021",
    decision: "Принятие решения о наделении управляющей организации ООО 'Веха' правом подписания договора по техническому обслуживанию внутриквартирного газового оборудования (ВКГО) от имени собственников многоквартирного дома."
  },
  {
    id: 125,
    address: "ул. Ленина, д. 7",
    category: "1Э/2021",
    protocolNumber: "14.12.2021",
    protocolDate: "14.12.2021",
    decision: "В связи с отсутствием кворума, общее собрание собственников жилых помещений является не состоявшимся."
  },
  {
    id: 126,
    address: "ул. Ленина, д. 11",
    category: "1Э/2021",
    protocolNumber: "21.12.2021",
    protocolDate: "21.12.2021",
    decision: "Принятие решения о наделении управляющей организации ООО 'Веха' правом подписания договора по техническому обслуживанию внутриквартирного газового оборудования (ВКГО) от имени собственников многоквартирного дома."
  },
  {
    id: 127,
    address: "ул. Ленина, д. 11",
    category: "2Э/2022",
    protocolNumber: "16.04.2022",
    protocolDate: "16.04.2022",
    decision: "Включить многоквартирный дом 11, ул. Ленина, рп. Софрино, г. Пушкино в план проведения капитального ремонта на 2023-2025 года."
  },
  {
    id: 128,
    address: "ул. Ленина, д. 13",
    category: "4Э/2021",
    protocolNumber: "16.06.2021",
    protocolDate: "16.06.2021",
    decision: "Принятие решения о наделении управляющей организации ООО 'Веха' правом подписания договора по техническому обслуживанию внутриквартирного газового оборудования (ВКГО) от имени собственников многоквартирного дома."
  },
  {
    id: 129,
    address: "ул. Ленина, д. 13",
    category: "3Э/2021",
    protocolNumber: "28.12.2020",
    protocolDate: "28.12.2020",
    decision: "В связи с отсутствием кворума, общее собрание собственников жилых помещений является не состоявшимся."
  },
  {
    id: 130,
    address: "ул. Ленина, д. 13",
    category: "1Э/2022",
    protocolNumber: "20.11.2020",
    protocolDate: "20.11.2020",
    decision: "В связи с отсутствием кворума, общее собрание собственников жилых помещений является не состоявшимся."
  },
  {
    id: 131,
    address: "ул. 1-я Южная, д.28",
    category: "3Э/2021",
    protocolNumber: "25.02.2022",
    protocolDate: "25.02.2022",
    decision: "Принятие решения о наделении управляющей организации ООО 'Веха' правом подписания договора по техническому обслуживанию внутриквартирного газового оборудования (ВКГО) от имени собственников многоквартирного дома."
  },
  {
    id: 132,
    address: "ул. 1-я Южная, д.28",
    category: "1Э/2020",
    protocolNumber: "20.02.2020",
    protocolDate: "20.02.2020",
    decision: "В связи с отсутствием кворума, общее собрание собственников жилых помещений является не состоявшимся."
  },
  {
    id: 133,
    address: "ул. 1-я Южная, д.26",
    category: "1Э/2022",
    protocolNumber: "25.02.2022",
    protocolDate: "25.02.2022",
    decision: "Принятие решения о наделении управляющей организации ООО 'Веха' правом подписания договора по техническому обслуживанию внутриквартирного газового оборудования (ВКГО) от имени собственников многоквартирного дома."
  },
  {
    id: 134,
    address: "ул. 1-я Южная, д.24",
    category: "1Э/2022",
    protocolNumber: "25.02.2022",
    protocolDate: "25.02.2022",
    decision: "В связи с отсутствием кворума, общее собрание собственников жилых помещений является не состоявшимся."
  },
  {
    id: 135,
    address: "ул. 1-я Южная, д.22",
    category: "1Э/2022",
    protocolNumber: "17.03.2022",
    protocolDate: "17.03.2022",
    decision: "Принятие решения о наделении управляющей организации ООО 'Веха' правом подписания договора по техническому обслуживанию внутриквартирного газового оборудования (ВКГО) от имени собственников многоквартирного дома."
  },
  {
    id: 136,
    address: "ул. 1-я Южная, д.20",
    category: "1Э/2022",
    protocolNumber: "22.06.2021",
    protocolDate: "22.06.2021",
    decision: "В связи с отсутствием кворума, общее собрание собственников жилых помещений является не состоявшимся."
  },
  {
    id: 137,
    address: "ул. 1-я Южная, д.20",
    category: "3Э/2022",
    protocolNumber: "17.03.2022",
    protocolDate: "17.03.2022",
    decision: "Принятие решения о наделении управляющей организации ООО 'Веха' правом подписания договора по техническому обслуживанию внутриквартирного газового оборудования (ВКГО) от имени собственников многоквартирного дома."
  },
  {
    id: 138,
    address: "ул. 1-я Южная, д.18",
    category: "1Э/2022",
    protocolNumber: "17.03.2022",
    protocolDate: "17.03.2022",
    decision: "Принятие решения о наделении управляющей организации ООО 'Веха' правом подписания договора по техническому обслуживанию внутриквартирного газового оборудования (ВКГО) от имени собственников многоквартирного дома."
  },
  {
    id: 139,
    address: "ул. 1-я Южная, д.18",
    category: "2Э/2022",
    protocolNumber: "16.04.2022",
    protocolDate: "16.04.2022",
    decision: "Включить в план капитального ремонта на 2023-2025 г. - капитальный ремонт кровли, фасада."
  },
  {
    id: 140,
    address: "ул. 1-я Южная, д.18",
    category: "1",
    protocolNumber: "02.03.2020",
    protocolDate: "02.03.2020",
    decision: "Выбор управляющей компании ООО 'Веха'; Заключение собственниками помещений в МКД, действующими от своего имени, договора, содержащего положения о предоставлении коммунальных услуг, договора на оказание услуг по обращению с ТКО."
  },
  {
    id: 141,
    address: "ул. Шоссейная, д.4",
    category: "2Э/2022",
    protocolNumber: "13.05.2022",
    protocolDate: "13.05.2022",
    decision: "В связи с отсутствием кворума, общее собрание собственников жилых помещений является не состоявшимся."
  },
  {
    id: 142,
    address: "ул. Шоссейная, д.4",
    category: "1Э/2022",
    protocolNumber: "21.01.2022",
    protocolDate: "21.01.2022",
    decision: "В связи с отсутствием кворума, общее собрание собственников жилых помещений является не состоявшимся."
  },
  {
    id: 143,
    address: "ул. Шоссейная, д.4",
    category: "3Э/2022",
    protocolNumber: "28.12.2022",
    protocolDate: "28.12.2022",
    decision: "Принятие решения о заключении собственниками жилых помещений МКД договора холодного водоснабжения, услуги по отоплению, оказание услуг по обращению с твердыми коммунальными отходами (ТКО) с ресурсоснабжающими организациями с '01' января 2023 года."
  },
  {
    id: 144,
    address: "ул. Менделеева, д. 31",
    category: "1Э/2021",
    protocolNumber: "28.12.2022",
    protocolDate: "28.12.2022",
    decision: "В связи с отсутствием кворума, общее собрание собственников жилых помещений является не состоявшимся."
  },
  {
    id: 145,
    address: "ул. Менделеева, д. 40",
    category: "1Э/2021",
    protocolNumber: "21.04.2021",
    protocolDate: "21.04.2021",
    decision: "Заключение собственниками помещений в МКД, действующими от своего имени, договора, содержащего положения о предоставлении коммунальных услуг, договора на оказание услуг по обращению с ТКО."
  },
  {
    id: 146,
    address: "ул. Менделеева, д. 40",
    category: "2Э/2022",
    protocolNumber: "21.01.2022",
    protocolDate: "21.01.2022",
    decision: "Принятие решения о наделении управляющей организации ООО 'Веха' правом подписания договора по техническому обслуживанию внутриквартирного газового оборудования (ВКГО) от имени собственников многоквартирного дома."
  },
  {
    id: 147,
    address: "ул. Орджоникидзе, д.41",
    category: "1Э/2022",
    protocolNumber: "21.01.2022",
    protocolDate: "21.01.2022",
    decision: "Принятие решения о наделении управляющей организации ООО 'Веха' правом подписания договора по техническому обслуживанию внутриквартирного газового оборудования (ВКГО) от имени собственников многоквартирного дома."
  },
  {
    id: 148,
    address: "д. Могильцы, д. 1",
    category: "-",
    protocolNumber: "-",
    protocolDate: "-",
    decision: "-"
  },
  {
    id: 149,
    address: "ул. Дальняя, д. 1",
    category: "1Э/2022",
    protocolNumber: "09.02.2022",
    protocolDate: "09.02.2022",
    decision: "В связи с отсутствием кворума, общее собрание собственников жилых помещений является не состоявшимся."
  },
  {
    id: 150,
    address: "ул. Дальняя, д. 1",
    category: "2Э/2022",
    protocolNumber: "06.05.2022",
    protocolDate: "06.05.2022",
    decision: "Включить в план капитального ремонта на 2023-2025 г. - капитальный ремонт кровли; фасада; отмостки; ВИС."
  },
  {
    id: 151,
    address: "ул. Дальняя, д. 1",
    category: "1",
    protocolNumber: "06.03.2020",
    protocolDate: "06.03.2020",
    decision: "Заключение собственниками помещений в МКД, действующими от своего имени, договора, содержащего положения о предоставлении коммунальных услуг, договора на оказание услуг по обращению с ТКО."
  },
  {
    id: 152,
    address: "ул. Дальняя, д. 2",
    category: "3Э/2022",
    protocolNumber: "06.05.2022",
    protocolDate: "06.05.2022",
    decision: "Включить в план капитального ремонта на 2023-2025 г. - капитальный ремонт кровли; фасада; отмостки; ВИС."
  },
  {
    id: 153,
    address: "ул. Дальняя, д. 2",
    category: "2Э/2021",
    protocolNumber: "18.11.2021",
    protocolDate: "18.11.2021",
    decision: "Принятие решения о наделении управляющей организации ООО 'Веха' правом подписания договора по техническому обслуживанию внутриквартирного газового оборудования (ВКГО) от имени собственников многоквартирного дома."
  },
  {
    id: 154,
    address: "ул. Дальняя, д. 2",
    category: "4Э/2022",
    protocolNumber: "12.12.2022",
    protocolDate: "12.12.2022",
    decision: "Принятие решения о заключении собственниками жилых помещений МКД договора холодного водоснабжения, горячего водоснабжения, водоотведения, услуги по отоплению, оказание услуг по обращению с твердыми коммунальными отходами (ТКО) с ресурсоснабжающими организациями с '01' января 2023 года."
  },
  {
    id: 155,
    address: "ул. Дальняя, д. 2",
    category: "1Э/2021",
    protocolNumber: "04.10.2021",
    protocolDate: "04.10.2021",
    decision: "В связи с отсутствием кворума, общее собрание собственников жилых помещений является не состоявшимся."
  },
  {
    id: 156,
    address: "ул. Дальняя, д. 3",
    category: "1Э/2021",
    protocolNumber: "04.10.2021",
    protocolDate: "04.10.2021",
    decision: "Принятие решения о наделении управляющей организации ООО 'Веха' правом подписания договора по техническому обслуживанию внутриквартирного газового оборудования (ВКГО) от имени собственников многоквартирного дома."
  },
  {
    id: 157,
    address: "ул. Дальняя, д. 4",
    category: "1Э/2022",
    protocolNumber: "17.06.2022",
    protocolDate: "17.06.2022",
    decision: "Включить в план капитального ремонта на 2023-2025 г. - капитальный ремонт кровли."
  },
  {
    id: 158,
    address: "ул. Дальняя, д. 5",
    category: "1Э/2021",
    protocolNumber: "06.10.2021",
    protocolDate: "06.10.2021",
    decision: "В связи с отсутствием кворума, общее собрание собственников жилых помещений является не состоявшимся."
  },
  {
    id: 159,
    address: "ул. Дальняя, д. 5",
    category: "2Э/2021",
    protocolNumber: "18.11.2021",
    protocolDate: "18.11.2021",
    decision: "Принятие решения о наделении управляющей организации ООО 'Веха' правом подписания договора по техническому обслуживанию внутриквартирного газового оборудования (ВКГО) от имени собственников многоквартирного дома."
  },
  {
    id: 160,
    address: "ул. Дальняя, д. 5",
    category: "3Э/2022/td>",
    protocolNumber: "17.06.2022",
    protocolDate: "17.06.2022",
    decision: "Принятие решения о наделении управляющей организации ООО 'Веха' правом подписания договора по техническому обслуживанию внутриквартирного газового оборудования (ВКГО) от имени собственников многоквартирного дома."
  },
  {
    id: 161,
    address: "ул. Дальняя, д. 6",
    category: "1Э/2021",
    protocolNumber: "06.10.2021",
    protocolDate: "06.10.2021",
    decision: "Принятие решения о наделении управляющей организации ООО 'Веха' правом подписания договора по техническому обслуживанию внутриквартирного газового оборудования (ВКГО) от имени собственников многоквартирного дома."
  },
  {
    id: 162,
    address: "ул. Экспериментальная,д.5",
    category: "2Э/2022",
    protocolNumber: "23.08.2022",
    protocolDate: "23.08.2022",
    decision: "Выбор управляющей компании ООО 'Веха'."
  },
  {
    id: 163,
    address: "ул. Экспериментальная,д.5",
    category: "1Э/2021",
    protocolNumber: "15.04.2021",
    protocolDate: "15.04.2021",
    decision: "Заключение собственниками помещений в МКД, действующими от своего имени, договора, содержащего положения о предоставлении коммунальных услуг, договора на оказание услуг по обращению с ТКО."
  },
  {
    id: 164,
    address: "ул. Экспериментальная,д.7",
    category: "1Э/2022",
    protocolNumber: "30.05.2022",
    protocolDate: "30.05.2022",
    decision: "Включить в план капитального ремонта на 2023-2025 г. - капитальный ремонт кровли."
  },
  {
    id: 165,
    address: "ул. Экспериментальная,д.7",
    category: "2Э/2022",
    protocolNumber: "28.05.2022",
    protocolDate: "28.05.2022",
    decision: "Выбор управляющей компании ООО 'Веха'."
  },
  {
    id: 166,
    address: "ул. Экспериментальная,д.7",
    category: "1",
    protocolNumber: "02.03.2020",
    protocolDate: "02.03.2020",
    decision: "Заключение собственниками помещений в МКД, действующими от своего имени, договора, содержащего положения о предоставлении коммунальных услуг, договора на оказание услуг по обращению с ТКО."
  },
  {
    id: 167,
    address: "ул. Экспериментальная,д.7",
    category: "2",
    protocolNumber: "18.09.2020",
    protocolDate: "18.09.2020",
    decision: "Выбор управляющей компании ООО 'Веха'."
  },
  {
    id: 168,
    address: "ул. Дурова, д. 13",
    category: "1Э/2020",
    protocolNumber: "16.12.2020",
    protocolDate: "16.12.2020",
    decision: "Заключение собственниками помещений в МКД, действующими от своего имени, договора, содержащего положения о предоставлении коммунальных услуг, договора на оказание услуг по обращению с ТКО."
  },
  {
    id: 169,
    address: "ул. Дурова, д. 17",
    category: "1Э/2020",
    protocolNumber: "16.12.2020",
    protocolDate: "16.12.2020",
    decision: "Заключение собственниками помещений в МКД, действующими от своего имени, договора, содержащего положения о предоставлении коммунальных услуг, договора на оказание услуг по обращению с ТКО."
  },
  {
    id: 170,
    address: "Талицы, д. 1",
    category: "1Э/2022",
    protocolNumber: "21.01.2022",
    protocolDate: "21.01.2022",
    decision: "Принятие решения о наделении управляющей организации ООО 'Веха' правом подписания договора по техническому обслуживанию внутриквартирного газового оборудования (ВКГО) от имени собственников."
  },
  {
    id: 171,
    address: "ул. Экспериментальная,д.7",
    category: "2",
    protocolNumber: "09.01.2023",
    protocolDate: "09.01.2023",
    decision: "Выбор управляющей компании ООО 'Веха'"
  },
  {
    id: 172,
    address: "ул. Экспериментальная,д.5",
    category: "1",
    protocolNumber: "20.12.2022",
    protocolDate: "20.12.2022",
    decision: "Утверждение доп"
  }
]