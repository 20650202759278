import staff from './staff.jpg';
import experience from './experience.jpg';
import clients from './clients.jpg';

export const aboutItems = [
{
picture: experience,
slogan: "Опыт и профессионализм",
textAbout: "За время деятельности с 2008г. компанией был накоплен значительный опыт проведения ремонтно-отделочных и монтажных работ любой сложности. Грамотный профессиональный подход, а также правильность и четкость действий всех специалистов компании позволяет нам выполнить работы на самом высоком уровне.",
layout: "left", 
},

{
    picture: clients,
    slogan: "Житель - наш приоритет!",
    textAbout: "Мы ориентированы на предоставление качественных услуг по обслуживанию, содержанию и ремонту многоквартирных домов. Мы стремимся работать эффективно и на предупреждение проблемных ситуаций. Житель всегда прав, рассчитывая на получение качественных услуг.",
    layout: "right",     
},

    {
        picture: staff,
        slogan: "Сотрудники - наша ценность!",
        textAbout: "ООО «Веха» - конкурентно способная компания, бережно относящаяся как к жителям, так и к собственным сотрудникам. Сотрудники компании являются профессионалами, имеющими большой опыт работы в сфере ЖКХ. Почти все из них работают с момента основания компании.",
        layout: "left"   
    }
]