export const KlubnayaData = [
    {
        title: "дом №1",
        year: '1957',
        floor: "2",
        apartment: "12",
        quantityLC: "18",
        livingArea: "626,7",
        notLivinigArea: "нет",
        commonProperty: "83,1",
        type: "многоэтажный жилой дом",
        level: "ХВС, отопление, канализация, газоснабжение, электроснабжение",
        technicalSupport: "нет",
        contractURL: 'klubnaya/sofrino_klubnaya_1_dogovor.pdf',
        protocolURL: 'protocol_4_2017-02-16.pdf',
    },

    {
        title: "дом №3",
        year: '1958',
        floor: "2",
        apartment: "12",
        quantityLC: "16",
        livingArea: "617,4",
        notLivinigArea: "нет",
        commonProperty: "80,7",
        type: "многоэтажный жилой дом",
        level: "ХВС, отопление, канализация, газоснабжение, электроснабжение",
        technicalSupport: "нет",
        contractURL: 'klubnaya/sofrino_klubnaya_3_dogovor.pdf',
        protocolURL: 'protocol_4_2017-02-16.pdf',
    },

    {
        title: "дом №5",
        year: '1990',
        floor: "3",
        apartment: "18",
        quantityLC: "18",
        livingArea: "813,2",
        notLivinigArea: "нет",
        commonProperty: "63,6",
        type: "многоэтажный жилой дом",
        level: "ХВС, отопление, канализация, газоснабжение, электроснабжение",
        technicalSupport: "нет",
        contractURL: 'klubnaya/sofrino_klubnaya_5_dogovor.pdf',
        protocolURL: 'protocol_4_2017-02-16.pdf',
    },

    {
        title: "дом №6",
        year: '1951',
        floor: "2",
        apartment: "8",
        quantityLC: "9",
        livingArea: "391,77",
        notLivinigArea: "нет",
        commonProperty: "45,9",
        type: "многоэтажный жилой дом",
        level: "ХВС, отопление, канализация, газоснабжение, электроснабжение",
        technicalSupport: "нет",
        contractURL: 'klubnaya/sofrino_klubnaya_6_dogovor.pdf',
        protocolURL: 'protocol_4_2017-02-16.pdf',
    },

    {
        title: "дом №8",
        year: '2015',
        floor: "3",
        apartment: "61",
        quantityLC: "61",
        livingArea: "3041,8",
        notLivinigArea: "нет",
        commonProperty: "475,9",
        type: "многоэтажный жилой дом",
        level: "ХВС, отопление, канализация, электроснабжение",
        technicalSupport: "общедомовые приборы учета ХВС, отопления, электроэнергии",
        contractURL: 'klubnaya/sofrino_klubnaya_8_dogovor.pdf',
        protocolURL: 'klubnaya/sofrino_klubnaya_8_protocol.pdf',
    },

    {
        title: "дом №9",
        year: '1994',
        floor: "3",
        apartment: "12",
        quantityLC: "12",
        livingArea: "545,3",
        notLivinigArea: "нет",
        commonProperty: "62,3",
        type: "многоэтажный жилой дом",
        level: "ХВС, отопление, канализация, газоснабжение, электроснабжение",
        technicalSupport: "общедомовой прибор учета ХВС",
        contractURL: 'klubnaya/sofrino_klubnaya_9_dogovor.pdf',
        protocolURL: 'protocol_4_2017-02-16.pdf',
    },

   {
        title: "дом №10",
        year: '1961',
        floor: "2",
        apartment: "16",
        quantityLC: "16",
        livingArea: "623,7",
        notLivinigArea: "нет",
        commonProperty: "47,3",
        type: "многоэтажный жилой дом",
        level: "ХВС, отопление, канализация, газоснабжение, электроснабжение",
        technicalSupport: "нет",
        contractURL: 'klubnaya/sofrino_klubnaya_10_dogovor.pdf',
        protocolURL: 'protocol_4_2017-02-16.pdf',
    },

    {
        title: "дом №12",
        year: '1963',
        floor: "2",
        apartment: "16",
        quantityLC: "16",
        livingArea: "615,3",
        notLivinigArea: "нет",
        commonProperty: "48,2",
        type: "многоэтажный жилой дом",
        level: "ХВС, отопление, канализация, газоснабжение, электроснабжение",
        technicalSupport: "нет",
        contractURL: 'klubnaya/sofrino_klubnaya_12_dogovor.pdf',
        protocolURL: 'protocol_4_2017-02-16.pdf',
    },

    {
        title: "дом №14",
        year: '1951',
        floor: "2",
        apartment: "8",
        quantityLC: "8",
        livingArea: "388,4",
        notLivinigArea: "нет",
        commonProperty: "42,8",
        type: "многоэтажный жилой дом",
        level: "ХВС, отопление, канализация, газоснабжение, электроснабжение",
        technicalSupport: "нет",
        contractURL: 'klubnaya/sofrino_klubnaya_14_dogovor.pdf',
        protocolURL: 'protocol_4_2017-02-16.pdf',
    }

]