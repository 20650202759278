export const KomsomolskayaData = [
    {
        title: "дом №13",
        year: '1964',
        floor: "4",
        apartment: "44",
        quantityLC: "45",
        livingArea: "2014,1",
        notLivinigArea: "нет",
        commonProperty: "148,2",
        type: "многоэтажный жилой дом",
        level: "ХВС, ГВС, отопление, канализация, газоснабжение, электроснабжение",
        technicalSupport: "нет",
        contractURL: 'komsomolskaya/sofrino_komsomolskaya_13_dogovor.pdf',
        protocolURL: 'protocol_4_2017-02-16.pdf',
    },

    {
        title: "дом №15",
        year: '1966',
        floor: "4",
        apartment: "48",
        quantityLC: "48",
        livingArea: "2035,9",
        notLivinigArea: "нет",
        commonProperty: "146,2",
        type: "многоэтажный жилой дом",
        level: "ХВС, ГВС, отопление, канализация, газоснабжение, электроснабжение",
        technicalSupport: "нет",
        contractURL: 'komsomolskaya/sofrino_komsomolskaya_15_dogovor.pdf',
        protocolURL: 'protocol_4_2017-02-16.pdf',
    },

    {
        title: "дом №17",
        year: '1941',
        floor: "2",
        apartment: "8",
        quantityLC: "20",
        livingArea: "689,3",
        notLivinigArea: "нет",
        commonProperty: "65",
        type: "многоэтажный жилой дом",
        level: "ХВС, ГВС, отопление, канализация, газоснабжение, электроснабжение",
        technicalSupport: "нет",
        contractURL: 'komsomolskaya/sofrino_komsomolskaya_17_dogovor.pdf',
        protocolURL: 'protocol_4_2017-02-16.pdf',
    },

    {
        title: "дом №21",
        year: '1969',
        floor: "4",
        apartment: "48",
        quantityLC: "49",
        livingArea: "2004,7",
        notLivinigArea: "нет",
        commonProperty: "147,7",
        type: "многоэтажный жилой дом",
        level: "ХВС, ГВС, отопление, канализация, газоснабжение, электроснабжение",
        technicalSupport: "нет",
        contractURL: 'komsomolskaya/sofrino_komsomolskaya_21_dogovor.pdf',
        protocolURL: 'protocol_4_2017-02-16.pdf',
    },

    {
        title: "дом №23",
        year: '1976',
        floor: "5",
        apartment: "70",
        quantityLC: "72",
        livingArea: "2886,3",
        notLivinigArea: "нет",
        commonProperty: "274,5",
        type: "многоэтажный жилой дом",
        level: "ХВС, ГВС, отопление, канализация, газоснабжение, электроснабжение",
        technicalSupport: "общедомовой прибор учета ХВС",
        contractURL: 'komsomolskaya/sofrino_komsomolskaya_23_dogovor.pdf',
        protocolURL: 'protocol_4_2017-02-16.pdf',
    },

    {
        title: "дом №25",
        year: '1986',
        floor: "5",
        apartment: "60",
        quantityLC: "60",
        livingArea: "3076,7",
        notLivinigArea: "нет",
        commonProperty: "280,6",
        type: "многоэтажный жилой дом",
        level: "ХВС, ГВС, отопление, канализация, газоснабжение, электроснабжение",
        technicalSupport: "общедомовой прибор учета ХВС",
        contractURL: 'komsomolskaya/sofrino_komsomolskaya_25_dogovor.pdf',
        protocolURL: 'protocol_4_2017-02-16.pdf',
    }

]