export const DalnyayaData = [
    {
        title: "дом №1",
        year: '1961',
        floor: "2",
        apartment: "16",
        quantityLC: "16",
        livingArea: "631,9",
        notLivinigArea: "нет",
        commonProperty: "45,8",
        type: "многоэтажный жилой дом",
        level: "ХВС, ГВС, отопление, канализация, газоснабжение, электроснабжение",
        technicalSupport: "нет",
        contractURL: 'dalnyaya/sofrino_dalnyaya_1_dogovor.pdf',
        protocolURL: 'protocol_4_2017-02-16.pdf',
    },

    {
        title: "дом №2",
        year: '1962',
        floor: "2",
        apartment: "16",
        quantityLC: "16",
        livingArea: "633,2",
        notLivinigArea: "нет",
        commonProperty: "45",
        type: "многоэтажный жилой дом",
        level: "ХВС, ГВС, отопление, канализация, газоснабжение, электроснабжение",
        technicalSupport: "нет",
        contractURL: 'dalnyaya/sofrino_dalnyaya_2_dogovor.pdf',
        protocolURL: 'protocol_1_2017-04-14.pdf',
    },

    {
        title: "дом №3",
        year: '1974',
        floor: "2",
        apartment: "22",
        quantityLC: "22",
        livingArea: "912,1",
        notLivinigArea: "нет",
        commonProperty: "85,5",
        type: "многоэтажный жилой дом",
        level: "ХВС, ГВС, отопление, канализация, газоснабжение, электроснабжение",
        technicalSupport: "нет",
        contractURL: 'dalnyaya/sofrino_dalnyaya_3_dogovor.pdf',
        protocolURL: 'protocol_1_2017-04-03.pdf',
    },

    {
        title: "дом №4",
        year: '1973',
        floor: "2",
        apartment: "22",
        quantityLC: "21",
        livingArea: "932",
        notLivinigArea: "нет",
        commonProperty: "82,7",
        type: "многоэтажный жилой дом",
        level: "ХВС, ГВС, отопление, канализация, газоснабжение, электроснабжение",
        technicalSupport: "нет",
        contractURL: 'dalnyaya/sofrino_dalnyaya_4_dogovor.pdf',
        protocolURL: 'protocol_1_2017-11-07.pdf',
    },

    {
        title: "дом №5",
        year: '1975',
        floor: "2",
        apartment: "22",
        quantityLC: "22",
        livingArea: "907,1",
        notLivinigArea: "нет",
        commonProperty: "82,5",
        type: "многоэтажный жилой дом",
        level: "ХВС, ГВС, отопление, канализация, газоснабжение, электроснабжение",
        technicalSupport: "нет",
        contractURL: 'dalnyaya/sofrino_dalnyaya_5_dogovor.pdf',
        protocolURL: 'protocol_1_2020-10-18.pdf',
    },

    {
        title: "дом №6",
        year: '1987',
        floor: "5",
        apartment: "42",
        quantityLC: "42",
        livingArea: "2126",
        notLivinigArea: "нет",
        commonProperty: "293,5",
        type: "многоэтажный жилой дом",
        level: "ХВС, ГВС, отопление, канализация, газоснабжение, электроснабжение",
        technicalSupport: "общедомовые приборы учета ХВС, ГВС, отопление",
        contractURL: 'dalnyaya/sofrino_dalnyaya_6_dogovor.pdf',
        protocolURL: 'protocol_1_2019-08-20.pdf',
    }

]