export const LeninaData = [
    {
        title: "дом №1",
        year: '1972',
        floor: "2",
        apartment: "16",
        quantityLC: "16",
        livingArea: "732,8",
        notLivinigArea: "нет",
        commonProperty: "58,6",
        type: "многоэтажный жилой дом",
        level: "ХВС, ГВС, отопление, канализация, газоснабжение, электроснабжение",
        technicalSupport: "нет",
        contractURL: 'lenina/sofrino_lenina_1_dogovor.pdf',
        protocolURL: 'protocol_4_2017-02-16.pdf',
    },

    {
        title: "дом №3",
        year: '1970',
        floor: "2",
        apartment: "16",
        quantityLC: "16",
        livingArea: "738,3",
        notLivinigArea: "нет",
        commonProperty: "58,2",
        type: "многоэтажный жилой дом",
        level: "ХВС, ГВС, отопление, канализация, газоснабжение, электроснабжение",
        technicalSupport: "нет",
        contractURL: 'lenina/sofrino_lenina_3_dogovor.pdf',
        protocolURL: 'protocol_4_2017-02-16.pdf',
    },

    {
        title: "дом №5",
        year: '1965',
        floor: "2",
        apartment: "16",
        quantityLC: "16",
        livingArea: "652,6",
        notLivinigArea: "нет",
        commonProperty: "50,2",
        type: "многоэтажный жилой дом",
        level: "ХВС, ГВС, отопление, канализация, газоснабжение, электроснабжение",
        technicalSupport: "нет",
        contractURL: 'lenina/sofrino_lenina_5_dogovor.pdf',
        protocolURL: 'protocol_4_2017-02-16.pdf',
    },

    {
        title: "дом №7",
        year: '1964',
        floor: "2",
        apartment: "16",
        quantityLC: "16",
        livingArea: "647,3",
        notLivinigArea: "нет",
        commonProperty: "49,5",
        type: "многоэтажный жилой дом",
        level: "ХВС, ГВС, отопление, канализация, газоснабжение, электроснабжение",
        technicalSupport: "нет",
        contractURL: 'lenina/sofrino_lenina_7_dogovor.pdf',
        protocolURL: 'protocol_4_2017-02-16.pdf',
    },

    {
        title: "дом №11",
        year: '1960',
        floor: "2",
        apartment: "8",
        quantityLC: "8",
        livingArea: "391,4",
        notLivinigArea: "нет",
        commonProperty: "43,6",
        type: "многоэтажный жилой дом",
        level: "ХВС, ГВС, отопление, канализация, газоснабжение, электроснабжение",
        technicalSupport: "общедомовой прибор учета электроэнергии",
        contractURL: 'lenina/sofrino_lenina_11_dogovor.pdf',
        protocolURL: 'protocol_4_2017-02-16.pdf',
    },

    {
        title: "дом №13",
        year: '1961',
        floor: "2",
        apartment: "5",
        quantityLC: "5",
        livingArea: "202,9",
        notLivinigArea: "нет",
        commonProperty: "37,6",
        type: "многоэтажный жилой дом",
        level: "ХВС, ГВС, отопление, канализация, газоснабжение, электроснабжение",
        technicalSupport: "общедомовой прибор учета электроэнергии",
        contractURL: 'lenina/sofrino_lenina_13_dogovor.pdf',
        protocolURL: 'protocol_4_2017-02-16.pdf',
    }

]